<template>
<navbar></navbar>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
        <div class="">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sideMenuVendor></sideMenuVendor>
              </div>

              <div class="col-lg-9">
                <div
                  class="tab-content myaccount-tab-content"
                  id="account-page-tab-content"
                >
                  <div
                    class="tab-pane fade show active"
                    id="account-dashboard"
                    role="tabpanel"
                    aria-labelledby="account-dashboard-tab"
                  >
                    <div
                      class=" pro-b-w-wrap"
                      style="margin-bottom: 35px"
                    >
                      <div class="row">
                        <div class="col-md-3">
                          <div class="profile-b-wrap">
                            <div class="">
                              <router-link to="/ActiveAuctions">
                                <i class="fa fa-hourglass-half one1"></i>
                                <h4>Active Auctions</h4></router-link
                              >

                              <router-link to="/ActiveAuctions"
                                ><h3>
                                  {{ dasBoardData.ongoing }}
                                </h3></router-link
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="profile-b-wrap">
                            <div class="">
                              <router-link to="/Pending">
                                <i class="fa fa-spinner one2"></i>
                                <h4>Pending Auctions</h4></router-link
                              >
                              <router-link to="/Pending">
                                <h3>
                                  {{ dasBoardData.pending }}
                                </h3></router-link
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="profile-b-wrap">
                            <div class="">
                              <router-link to="/WonCustomerList">
                                <i class="fa fa-trophy one3"></i>
                                <h4>Won Auctions</h4>
                                <router-link
                                  to="/WonCustomerList"
                                ></router-link>
                                <h3>
                                  {{ dasBoardData.wonAuction }}
                                </h3></router-link
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="profile-b-wrap">
                            <div class="">
                              <router-link to="/Delivered">
                                <i class="fa fa-check-circle one4"></i>
                                <h4>Delivered Auctions</h4></router-link
                              >
                              <router-link to="/Delivered">
                                <h3>
                                  {{ dasBoardData.delivered }}
                                </h3></router-link
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="" style="margin-bottom: 18px">
                      <div class="l-section">
                        <!-- <h5 class="c-section__heading">Details</h5>   -->

                        <!-- form start -->
                        <Form
                          @submit="onSubmit"
                          :validation-schema="schema"
                          v-slot="{ errors }"
                        >
                          <div>
                            <div class="row">
                              <div class="col-md-6">
                                <div>
                                  <h5 style="font-weight: 600; color: #5e5e5e">
                                    Basic Details
                                  </h5>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div>
                                  <a
                                    class="click-to-edit"
                                    style="float: right"
                                    variant="outline-primary"
                                    @click="enableEdit()"
                                  >
                                    Click To Edit
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-md-6">
                              <label class="labelzzz">Full Name</label>
                              <Field
                                name="Name"
                                type="text"
                                v-model="currentuserinfo.sellerName"
                                class="form-control inputzzz"
                                :class="{ 'is-invalid': errors.Name }"
                                :disabled="invalid"
                                placeholder="Enter your name"
                              />
                              <div class="invalid-feedback">
                                {{ errors.Name }}
                              </div>
                            </div>

                            <div class="form-group col-md-6">
                              <label class="labelzzz">Email</label>
                              <Field
                                name="email"
                                type="text"
                                v-model="currentuserinfo.sellerEmail"
                                class="form-control inputzzz"
                                :class="{ 'is-invalid': errors.email }"
                                :disabled="invalid"
                                placeholder="Enter your  email"
                              />
                              <div class="invalid-feedback">
                                {{ errors.email }}
                              </div>
                            </div>

                            <div class="form-group col-md-6">
                              <label class="labelzzz">Phone</label>
                              <div class="">
                                <div class="makeinside countryFlagimg">
                                  <img
                                    src="/assets/images/menu/flag/897.png"
                                    alt="Canada Flag"
                                    class=""
                                    style=""
                                  /><span>+1</span>
                                </div>
                                <!-- <div class="col-1 col-md-1 coutryCode">
                                  <label for="phone" class=""> +1</label>
                                </div> -->
                                <div class="phoneNUmberCls">
                                  <Field
                                    name="phone"
                                    type="number"
                                    v-model="currentuserinfo.sellerPhone"
                                    class="form-control inputzzz"
                                    :class="{ 'is-invalid': errors.phone }"
                                    :disabled="invalid"
                                    placeholder="Enter your phone number"
                                  />
                                </div>
                              </div>

                              <div class="invalid-feedback">
                                {{ errors.phone }}
                              </div>
                            </div>

                            <div class="form-group col-md-6">
                              <label class="labelzzz">Address</label>
                              <Field
                                name="address"
                                type="text"
                                v-model="currentuserinfo.address"
                                class="form-control inputzzz"
                                :class="{ 'is-invalid': errors.address }"
                                :disabled="invalid"
                                placeholder="Enter your address"
                              />
                              <div class="invalid-feedback">
                                {{ errors.address }}
                              </div>
                            </div>
                          </div>

                          <div class="form-group">
                            <!-- <p>{{ message }}</p> -->
                            <button style="    margin-top: 8px;"
                              type="submit"
                              class="btn btn-danger mr-1"
                              :disabled="invalid"
                            >
                              UPDATE
                            </button>
                          </div>
                          <div></div>
                        </Form>
                        <!-- form end  -->
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <ChangePasswordVendor></ChangePasswordVendor> 
                </div>
              </div>
            </div>
            

          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import ChangePasswordVendor from "@/components/vendor/ChangePasswordVendor.vue";
import navbar from "@/components/navbar.vue";
import userService from "@/services/user.service";
import sideMenuVendor from "../sideMenuVendor.vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
export default {
  name: "Profile",
  components: {
    sideMenuVendor,
    Form,
    Field,
    navbar,
    ChangePasswordVendor
  },
  data() {
    const schema = Yup.object().shape({
      Name: Yup.string()
        .required("User name is required")
        .min(3, "Must be more than 3 characters"),
      address: Yup.string()
        .required("Address name is required")
        .min(3, "Must be more than 3 characters"),
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      phone: Yup.number("Must be Number")       
        .required("This field is requried")
      
    });

    return {
      schema,
      invalid: true,
      userinfo: [], 
      currentuserinfo: [],
      message: "",
      Errormessage: "",
      dasBoardData:""
    };
  },

  methods: {
    onSubmit(values) {
      let user = JSON.parse(localStorage.getItem("user"));

      let id = user.sellerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .updateSellerProfile(id, values)
          .then((response) => {
            this.message = response.data.Message;
     
            this.$toast.success("Updated successfully", {
              position: "top",
              duration: 3000,
              queue: true
            });
            setTimeout(() => {
              location.reload();
            }, 3000);

            window.scrollTo(0, 0);
          })
          .catch((err) => {
            this.message = err.response.data.Message;
            this.$toast.error("!Oops something went wrong. try again", {
              position: "top",
              duration: 3000,
              queue: true
            });
            // console.log(err.response.data.Message);
          });
      }
    },
    enableEdit() {
      this.invalid = !this.invalid;
    },
    getsellerbyId(custId) {
      if (custId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getsellerbyId(custId)
          .then((response) => {
            this.currentuserinfo = response.data.Data[0];
            // console.log(this.currentuserinfo);
            window.scrollTo(0, 0);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getDashboardProfileFe(custId,type) {
      if (custId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getDashboardProfileFe(custId,type)
          .then((response) => {
            this.dasBoardData = response.data.Data[0];
          // console.log(this.dasBoardData.pending);
            window.scrollTo(0, 0);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
   
    if (this.loggedIn) {
      this.userinfo = JSON.parse(localStorage.getItem("user"));

      if (typeof this.userinfo.sellerId != "undefined") {
        this.getsellerbyId(this.userinfo.sellerId);
        this.getDashboardProfileFe(this.userinfo.sellerId,'vendor');
      }
    }
  },
};
</script>
<style>
.coutryCode label {
  margin-top: 6px;
  display: block;
}
</style>
